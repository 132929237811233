//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadSource from './uploadSource'
import E from 'wangeditor'
let _this = null
const { BtnMenu } = E
class imgLibraryMenu extends BtnMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = E.$(
      `<div class="w-e-menu" data-title="上传图片">
            <i style="font-size:17px;" class="el-icon-picture"><i>
            </div>`
    )
    super($elem, editor)
  }
  // 菜单点击事件
  clickHandler() {
    _this.showImgLibrary = !0
  }
  tryChangeActive() {}
}
class videoLibraryMenu extends BtnMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = E.$(
      `<div class="w-e-menu" data-title="上传视频">
            <i style="font-size:17px;" class="el-icon-video-camera"><i>
            </div>`
    )
    super($elem, editor)
  }
  // 菜单点击事件
  clickHandler() {
    _this.showVideoLibrary = !0
  }
  tryChangeActive() {}
}
export default {
  props: {
    width: {
      type: Number,
      default: 900
    },
    height: {
      type: Number,
      default: 600
    },
    richTxt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showImgLibrary: !1,
      showVideoLibrary: !1,
      editor: null
    }
  },
  watch: {
    richTxt: {
      handler(val) {
        if (this.editor) this.editor.txt.html(val)
      },
      immediate: true
    }
  },
  components: {
    UploadSource
  },
  mounted() {
    _this = this
    const menuKey = 'imgLibraryMenuKey'
    const videoKey = 'videoLibraryMenuKey'
    this.editor = new E('#editor')
    // 注册菜单
    this.editor.menus.extend(menuKey, imgLibraryMenu)
    this.editor.menus.extend(videoKey, videoLibraryMenu)
    this.editor.config.menus = this.editor.config.menus.concat(menuKey)
    this.editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'todo',
      'justify',
      'quote',
      'imgLibraryMenuKey',
      'videoLibraryMenuKey',
      // 'image',
      // 'video',
      'table',
      'code',
      'splitLine',
      'undo',
      'redo'
    ]
    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      resultFiles.map(async (item) => {
        let url = await _this.uploadImg(item, 'files')
        insertImgFn(url)
      })
    }
    this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
      resultFiles.map(async (item) => {
        let url = await _this.uploadImg(item, 'files')
        insertVideoFn(url)
      })
    }
    this.editor.config.onchange = function (newHtml) {
      _this.$emit('soninfo', newHtml)
    }
    this.editor.config.menuTooltipPosition = 'down'
    this.editor.config.height = this.height
    this.editor.create()
    this.editor.txt.html(this.richTxt)
  },
  methods: {
    changeStatus(val) {
      this.showImgLibrary = val
    },
    changeStatusVideo(val) {
      this.showVideoLibrary = val
    },
    getImg(val) {
      for (let i in val) {
        this.editor.cmd.do('insertHTML', `<img src="${val[i].path}" />`)
      }
    },
    getVideo(val) {
      for (let i in val) {
        this.editor.cmd.do('insertHTML', `<video src="${val[i].path}" autoplay loop></vidoe>`)
      }
    },
    uploadImg(file, type) {
      return new Promise((resolve) => {
        let formData = new FormData()  
        formData.append(type, file)
        this.$axios.uploadFile(this.$api.user.addFiles, formData).then((res) => {
          if (res.code == 0) {
            resolve(res.result.path)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  }
}
